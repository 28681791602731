<template>
  <v-card
    class="mt-2"
    :color="disabled ? '#d3d3d3' : 'white'"
  >
    <v-card-title class="headline primary white--text">
      {{ title }}
    </v-card-title>
    <v-progress-linear
      v-if="loading"
      class="ma-0"
      indeterminate
    />
    <v-card-text>
      <v-textarea
        auto-grow
        counter
        :rules="rules"
        v-model="notes"
        :disabled="disabled"
      />
      <v-btn
        :color="save_failed ? 'error' : 'primary'"
        :disabled="disabled || !notes_modified || notes.length > 1024"
        @click="save(notes)"
        data-cy="save-note"
      >
        Save
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import api from '../api.js'
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    eventId: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notes: '',
      orig_notes: '',
      save_failed: false,
      rules: [v => v.length <= 1024 || 'Max 1024 characters']
    }
  },
  methods: {
    ...mapMutations([
      'logError'
    ]),
    save (notes) {
      api.saveReservationNotes(this.eventId, notes)
        .then(response => {
          this.save_failed = false
          this.orig_notes = notes
        })
        .catch(e => {
          this.save_failed = true
          this.logError(e, { root: true })
        })
    }
  },
  computed: {
    notes_modified () { return this.notes !== this.orig_notes },
    disabled () { return !this.eventId }
  },
  watch: {
    eventId: function () {
      api.pullReservationNotes(this.eventId)
        .then(response => {
          this.notes = response.data.notes
          this.orig_notes = response.data.notes
        })
        .catch(e => {
          if (e.response.status === 404) {
            // 404 -- No notes found
            this.notes = ''
            this.orig_notes = ''
          } else {
            this.logError(e, { root: true })
          }
        })
    }
  }
}
</script>

<style>
</style>
