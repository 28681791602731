<template>
  <v-container
    fluid
    grid-list-md
  >
    <v-layout>
      <v-flex
        xs12
        md5
      >
        <v-card>
          <v-card-title class="headline primary white--text">
            Upcoming Reservations (Approved)
          </v-card-title>
          <v-list two-line>
            <event
              v-for="(reservation, ind) in currentItems"
              :key="ind"
              :event="reservation"
              :class="{
                'selected-reservation': reservation.parent_id === previewEventId
              }"
              data-cy="approved-reservation"
            >
              <v-list-tile
                slot-scope="{ formatTitleDate, eventStartingSoon, getStatus }"
              >
                <v-list-tile-action>
                  <v-tooltip top>
                    <status-light
                      slot="activator"
                      :status="getStatus"
                    />
                    <span>{{ getStatus }}</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ reservation.reservation_desc }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title
                    :class="{
                      'error--text font-weight-bold': eventStartingSoon
                    }"
                  >
                    {{ reservation.reservation_title }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title
                    :class="{
                      'error--text font-weight-bold': eventStartingSoon
                    }"
                  >
                    {{ formatTitleDate() }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-tooltip
                  top
                  v-if="reservation.reservation_desc === null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <font-awesome-icon
                      size="2x"
                      class="error--text"
                      :icon="['far', 'exclamation-circle']"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>Reservation info incomplete</span>
                </v-tooltip>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      flat
                      :disabled="reservation.parent_id === previewEventId"
                      slot="activator"
                      @click="setPreview(reservation)"
                      data-cy="preview-approved"
                    >
                      <font-awesome-icon
                        :icon="['far', 'file-invoice']"
                        size="2x"
                      />
                    </v-btn>
                    <span>Preview</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      flat
                      slot="activator"
                      @click="openDialog(reservation, 'cancel')"
                    >
                      <font-awesome-icon
                        size="2x"
                        color="tomato"
                        :icon="['far', 'ban']"
                        data-cy="cancel-approved"
                      />
                    </v-btn>
                    <span>Cancel booked reservation</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </event>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="prevPage"
              :disabled="onFirstPage"
              color="primary"
            >
              Prev
            </v-btn>
            <v-btn
              @click="nextPage"
              :disabled="onLastPage"
              color="primary"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
        <ReservationNotes
          title="Reservation Notes"
          :event-id="event_id"
          :loading="loading"
        />
      </v-flex>

      <v-flex
        xs12
        md7
      >
        <v-card>
          <v-card-title class="headline primary white--text">
            Pending Reservations
          </v-card-title>
          <v-list
            two-line
            v-if="pendingReservations.length > 0"
          >
            <event
              v-for="(reservation, ind) in pendingReservations"
              :key="ind"
              :event="reservation"
              :class="{
                'selected-reservation': reservation.event_id === previewEventId
              }"
              data-cy="pending-reservation"
            >
              <v-list-tile
                slot-scope="{ formatTitleDate, eventStartingSoon, getStatus }"
              >
                <v-list-tile-action>
                  <v-tooltip top>
                    <status-light
                      slot="activator"
                      :status="getStatus"
                    />
                    <span>{{ getStatus }}</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ reservation.reservation_desc }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title
                    :class="{
                      'error--text font-weight-bold': eventStartingSoon
                    }"
                  >
                    {{ reservation.reservation_title }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title
                    :class="{
                      'error--text font-weight-bold': eventStartingSoon
                    }"
                  >
                    {{ formatTitleDate() }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-tooltip
                  top
                  v-if="reservation.reservation_desc === null"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <font-awesome-icon
                      size="2x"
                      class="error--text mr-4"
                      :icon="['far', 'exclamation-circle']"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>Reservation info incomplete</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="!reservation.rps_status && reservation.reservation_equipment.indexOf('WMKZ') > -1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <font-awesome-icon
                      size="2x"
                      class="error--text mr-4"
                      :icon="['far', 'exclamation-circle']"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>RPS Screening Required!</span>
                </v-tooltip>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      :disabled="
                        reservation.event_id === previewEventId ||
                          reservation.reservation_desc === null
                      "
                      flat
                      slot="activator"
                      @click="setPreview(reservation)"
                      data-cy="pending-preview"
                    >
                      <font-awesome-icon
                        :icon="['far', 'file-invoice']"
                        size="2x"
                      />
                    </v-btn>
                    <span>Preview</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      flat
                      slot="activator"
                      @click="openDialog(reservation, 'deny')"
                      data-cy="deny-button"
                    >
                      <font-awesome-icon
                        :icon="['far', 'ban']"
                        class="error--text"
                        size="2x"
                      />
                    </v-btn>
                    <span>Deny</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      flat
                      slot="activator"
                      @click="openDialog(reservation, 'cancel')"
                      data-cy="cancel-button"
                    >
                      <font-awesome-icon
                        :icon="['far', 'times-circle']"
                        size="2x"
                      />
                    </v-btn>
                    <span>Cancel</span>
                  </v-tooltip>
                </v-list-tile-action>
                <v-list-tile-action>
                  <v-tooltip top>
                    <v-btn
                      flat
                      slot="activator"
                      @click="openDialog(reservation, 'approve')"
                      data-cy="approve-button"
                    >
                      <font-awesome-icon
                        :icon="['far', 'check']"
                        size="2x"
                        class="success--text"
                      />
                    </v-btn>
                    <span>Approve</span>
                  </v-tooltip>
                </v-list-tile-action>
              </v-list-tile>
            </event>
          </v-list>
          <v-card-text
            v-else
            class="subheading"
          >
            No pending reservations.
          </v-card-text>
        </v-card>
        <reservation-preview
          :loading="loading"
          class="mt-2"
          disablable
          admin
          :readonly="false"
        />
      </v-flex>

      <v-dialog
        v-if="dialogAction !== null"
        v-model="isOpen"
        width="500"
      >
        <v-card data-cy="workflow-dialog">
          <v-card-title class="primary headline white--text">
            {{ dialogAction.title }}
          </v-card-title>
          <v-progress-linear
            v-if="dialogNotesLoading"
            class="ma-0"
            indeterminate
          />
          <v-card-text
            v-else
            class="ml-4 mr-4 justify-left-children"
          >
            <span v-if="selectedReservation.reservation_desc === null">
              <h3 style="color:darkred; display: inline; text-align: left;">
                Warning:
              </h3>
              <h3 style="display: inline; text-align: left;">
                This reservation is missing information
              </h3>
            </span>
            <span v-if="dialogNotes">
              <h2>Reservation Notes</h2>
              <p>{{ dialogNotes }}</p>
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="isOpen = false"
              data-cy="workflow-dialog-dismiss"
            >
              Go Back
            </v-btn>
            <v-btn
              @click="dialogAction.action"
              :color="dialogAction.actionColor"
              data-cy="workflow-dialog-action"
            >
              {{ dialogAction.actionText }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import api from '../api.js'
import Event from './Event'
import StatusLight from './StatusLight'
import ReservationPreview from './ReservationPreview'
import ReservationNotes from './ReservationNotes'
export default {
  components: {
    Event,
    StatusLight,
    ReservationPreview,
    ReservationNotes
  },
  data () {
    return {
      reservationLoading: false,
      isOpen: false,
      selectedReservation: null,
      numPerPage: 10,
      currentPage: 1,
      previewEventId: null,
      dialogNotes: null,
      dialogNotesLoading: false,
      dialogAction: null,
      denyDialog: {
        title: 'Deny Confirmation',
        action: this.denyReservation,
        actionColor: 'error',
        actionText: 'Deny Reservation'
      },
      approveDialog: {
        title: 'Approval Confirmation',
        action: this.approveReservation,
        actionColor: 'primary',
        actionText: 'Approve Reservation'
      },
      cancelDialog: {
        title: 'Cancellation Confirmation',
        action: this.cancelReservation,
        actionColor: 'primary',
        actionText: 'Cancel Reservation'
      }
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getReservationsAdminAction',
      'getPendingReservationsAction',
      'getReservationAction',
      'workflowApproveAction',
      'workflowDenyAction',
      'cancelEventAction'
    ]),
    ...mapMutations('reservation', [
      'setCurrentReservation',
      'resetReservationForm'
    ]),
    pullDialogNotes (eventId) {
      this.dialogNotesLoading = true
      api
        .pullReservationNotes(eventId)
        .then(response => {
          this.dialogNotes = response.data.notes
          this.dialogNotesLoading = false
        })
        .catch(e => {
          if (e.response.status === 404) {
            // 404 -- No notes found
            this.dialogNotes = null
            this.dialogNotesLoading = false
          } else {
            this.logError(e, { root: true })
          }
        })
    },
    openDialog (reservation, dialogType) {
      this.selectedReservation = reservation
      this.pullDialogNotes(
        reservation.event_id
          ? reservation.event_id.substr(0, 26)
          : reservation.parent_id
      )
      if (dialogType === 'deny') {
        this.dialogAction = this.denyDialog
      } else if (dialogType === 'approve') {
        this.dialogAction = this.approveDialog
      } else if (dialogType === 'cancel') {
        this.dialogAction = this.cancelDialog
      }
      this.isOpen = true
    },
    approveReservation () {
      this.workflowApproveAction(
        this.selectedReservation.original_event ||
          this.selectedReservation.event_id
      ).then((this.isOpen = false))
    },
    cancelReservation () {
      this.cancelEventAction(
        this.selectedReservation.original_event ||
          this.selectedReservation.event_id ||
          this.selectedReservation.parent_id
      ).then(
        (this.isOpen = false)
      )
    },
    denyReservation () {
      this.workflowDenyAction(
        this.selectedReservation.original_event ||
          this.selectedReservation.event_id
      ).then((this.isOpen = false))
    },
    setPreview (reservation) {
      // OR allows this funtion to be used with different reservation event types
      this.previewEventId = reservation.event_id || reservation.parent_id
      if (reservation.reservation_desc !== null) {
        this.getReservationAction(
          reservation.original_event || reservation.parent_id
        )
      } else {
        // if filled in from gcal, then fill in what we can
        this.setCurrentReservation({
          addl_contacts: [],
          approved: reservation.approved,
          billing_address: '',
          billing_email: '',
          billing_info: '',
          billing_name: '',
          billing_phone: '',
          cancelled: false,
          company_name: '',
          data_collection: '',
          data_sharing: null,
          end_date: reservation.end_date,
          event_id: reservation.parent_id,
          facility_its: '',
          facility_prep: '',
          guest_office: false,
          issues: null,
          media: [],
          original_event: null,
          partial_reservation: '',
          pending: false,
          primary_email: reservation.primary_email,
          primary_name: '',
          primary_phone: '',
          rate_override: null,
          reservation_desc: '',
          reservation_equipment: [],
          reservation_key: -1,
          reservation_title: reservation.reservation_title,
          safety_plan_key: -1,
          safety_plan_media: null,
          safety_plan_state: 'pending_mcity',
          start_date: reservation.start_date,
          timeslot: null,
          vehicles: []
        })
      }
    },
    nextPage () {
      if (!this.onLastPage) this.currentPage++
    },
    prevPage () {
      if (!this.onFirstPage) this.currentPage--
    }
  },
  computed: {
    ...mapGetters('reservation', ['approvedReservations']),
    ...mapState('reservation', {
      pendingReservations: state => state.pendingReservations,
      reservation_not_loaded: state => state.currentReservation === null,
      event_id: state =>
        state.currentReservation
          ? state.currentReservation.event_id
            ? state.currentReservation.event_id
            : state.currentReservation.parent_id
          : null
    }),
    loading () {
      // Substring removes recurring event instance information
      const eventID = this.event_id ? this.event_id.substr(0, 26) : ''
      return this.previewEventId
        ? this.previewEventId.substr(0, 26) !== eventID
        : false
    },
    currentItems () {
      const startIndex = (this.currentPage - 1) * this.numPerPage
      return this.approvedReservations.slice(
        startIndex,
        startIndex + this.numPerPage
      )
    },
    onFirstPage () {
      return this.currentPage === 1
    },
    onLastPage () {
      return (
        this.currentPage >= this.approvedReservations.length / this.numPerPage
      )
    }
  },
  created () {
    this.getPendingReservationsAction()
    this.getReservationsAdminAction()
    this.resetReservationForm()
  }
}
</script>

<style>
.justify-left-children > * {
  text-align: left;
}
.disabled-btn {
  opacity: 0.5;
}
.selected-reservation {
  background-color: cornsilk;
}
</style>
